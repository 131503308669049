import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../../style/constants.js';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    backgroundColor: colors.TURQUOISE,
    minWidth: 200,
    height: 45,
    borderRadius: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 40px 0 0px',
    boxSizing: 'border-box',
    fontWeight: 500,
    fontSize: '0.875em',
    textTransform: 'uppercase',
    color: colors.WHITE,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      height: 40,
      minWidth: 190,
      '& svg': {
        width: '30px!important',
        height: '30px!important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.125em',
      height: 35,
      minWidth: 160,
      '& svg': {
        width: '25px!important',
        height: '25px!important',
      },
    },
    '& span': {
      padding: '0 10px',
    },
    '& svg': {
      position: 'absolute',
      width: 35,
      height: 35,
      right: 5,
      top: 5
    },
    '&:hover': {
      backgroundColor: colors.LIGHT_BLUE
    }
  }
}));

const InnerLinkButton = (props) => {
  const classes = useStyles();
  return(
    <button className={classes.button + " clickable go-to-button"} onClick={props.onClick} type={props.type}>
      <span>{props.text}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.79 32.79">
        <g>
          <circle fill="none" stroke="#fff" strokeWidth="2px" strokeMiterlimit="10" cx="16.4" cy="16.4" r="15.4"/>
          {props.plus ? 
          <>
          <line x1="16.29" y1="24.47" x2="16.29" y2="8.32" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
          <line x1="24.37" y1="16.4" x2="8.22" y2="16.4" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2px"/>
          </>
          :
          <>
          <polyline fill="none" stroke="#fff" strokeWidth="2px" strokeLinecap="round" strokeLinejoin="round" points="18.14 10.17 24.37 16.4 18.14 22.63"/>
          <line fill="none" stroke="#fff" strokeWidth="2px" strokeLinecap="round" strokeLinejoin="round" x1="24.37" y1="16.4" x2="8.22" y2="16.4"/>
          </>
          }
        </g>
      </svg>
    </button>
  )
};

export default InnerLinkButton;